<template>
  <div>
    <NavbarNotLoggedIn />
    <div class="min-h-[90vh] h-full">
      <div class="col-span-7 mb-5 flex justify-center items-center" :style="{ backgroundImage: 'url(' + require('@/assets/cus_app_background.jpg') + ')' }" style="height: 600px; background-repeat: no-repeat">
        <!-- <div class="col-span-7 mb-5 flex justify-center items-center" :style="{ backgroundImage: 'url(' + require('@/assets/lol2.jpeg') + ')' }" style="height: 600px; background-size: cover; box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5); background-repeat: no-repeat"> -->
        <!-- <div class="col-span-7 mb-5 flex justify-center items-center" :style="{ backgroundImage: 'url(' + require('@/assets/lol.jpeg') + ')' }" style="height: 1100px; background-size: cover; background-repeat: no-repeat"> -->
        <div class="text-center">
          <p class="text-6xl p-1 text-[#FFBD5A]">Healthy Harvest <span class="text-gray-200">Organic Farmhouse</span></p>
          <p class="text-3xl text-white">Quality Milk and Meat & <span class="text-[#FFBD5A]">Qurbani</span> right at your doorstep</p>
          <button @click="toLiveSotck()" class="bg-[#FFBD5A] text-gray-100 py-2 hover:underline px-8 rounded-3xl mt-10 mx-1 text-lg hover:scale-105">Explore More</button>
          <button class="translate-x-1 border-2 border-gray-100 text-gray-100 py-2 hover:underline px-8 rounded-3xl mt-10 mx-1 text-lg hover:scale-105">General Store</button>
        </div>
      </div>
      <div class="grid grid-cols-7">
        <div class="col-span-2"></div>
        <div class="col-span-3 mt-2">
          <p class="text-3xl underline">Find Our Categories</p>
        </div>
        <div class="col-span-2"></div>
        <div class="col-span-2"></div>
        <div class="col-span-3 flex justify-center mt-2">
          <div v-for="d in dataType" :key="d" class="bg-gray-200 shadow-lg hover:shadow-xl max-h-40 w-40 rounded-md mx-4 hover:bg-gray-400 hover:font-bold hover:cursor-pointer">
            <div class="grid grid-rows-5">
              <div class="row-span-4"><img :src="d.img" class="px-6" /></div>
              <div class="row-span-1">
                <p>{{ d.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-2"></div>

        <template v-if="bannerCheck">
          <div class="col-span-2"></div>
          <div class="col-span-3 my-5 slideshow">
            <img class="w-full h-60" :src="dataBanner[currentImageIndex].iamge" alt="Current image" />
          </div>
          <div class="col-span-2"></div>
        </template>
      </div>
    </div>
    <!--  Footer container -->
    <footer class="bg-neutral-100 text-center text-neutral-600 dark:bg-neutral-600 dark:text-neutral-200 lg:text-left">
      <div class="bg-neutral-200 p-6 text-center dark:bg-neutral-700">
        <span>© 2023 Copyright:Healthy Harves</span>
      </div>
    </footer>
  </div>
</template>
<script>
import NavbarNotLoggedIn from '@/components/NavbarNotLoggedIn.vue';
import api from '../../boot/axios.js';
export default {
  components: { NavbarNotLoggedIn },
  data() {
    return {
      dataBanner: [],
      dataType: [],
      dataTypeImage: [],
      currentImageIndex: 0,
      bannerCheck: false,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    setInterval(() => {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.dataBanner.length;
    }, 5000);
  },
  methods: {
    async getData() {
      await api
        .get(`api/banner/`)
        .then((res) => {
          this.dataBanner = res.data;
          if (this.dataBanner.length > 0) {
            this.bannerCheck = true;
          }
          console.table(this.dataBanner);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/type_prod/`)
        .then((res) => {
          this.dataType = res.data;
          // console.table(this.dataType);
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get(`api/type_prod_img/`)
        .then((res) => {
          this.dataTypeImage = res.data;
          // console.table(this.dataTypeImage);
        })
        .catch((error) => {
          console.log(error);
        });
      for (let i = 0; i < this.dataTypeImage.length; i++) {
        for (let j = 0; j < this.dataType.length; j++) {
          if (this.dataType[j].id == this.dataTypeImage[i].typeId) {
            this.dataType[j].img = this.dataTypeImage[i].image;
          }
        }
      }
      console.table(this.dataType);
    },
    toLiveSotck() {
      this.$router.push({ name: 'LiveStock' });
    },
  },
};
</script>
<style>
.slideshow-image {
  animation-name: slide-in-out;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes slide-in-out {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}
</style>