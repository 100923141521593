<template>
  <div>
    <div class="hidden sm:block"><router-view /></div>
    <div class="sm:hidden h-screen bg-slate-800">
      <div class="flex justify-center items-center h-[60px] bg-gray-100">
        <img class="h-14" src="@/assets/logo.png" alt="Your Company" />
        <p class="text-lg text-slate-900">Healthy Harvest</p>
      </div>
      <div class="flex items-center justify-center h-[90vh]">
        <div>
          <p class="text-lg text-white">Please download the App</p>
          <div class="flex justify-center items-center">
            <img class="h-10 cursor-pointer m-1" src="@/assets/play_store.png" alt="Your Company" />
            <img class="h-10 cursor-pointer m-1" src="@/assets/apple_store.png" alt="Your Company" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import './assets/tailwind.css';

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    currentRouteName() {
      // console.log(this.$route.name);
      return this.$route.name;
    },
  },

  methods: {},
};
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;700&display=swap');
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>