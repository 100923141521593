<template>
  <div @click="ShowCart()" class="relative hover:cursor-pointer">
    <i class="fa-solid fa-cart-shopping fa-xl text-yellow-500"></i>
    <span v-if="numItems > 0" class="absolute bottom-5 left-5 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full">{{ numItems }}</span>
  </div>
</template>

<script>
export default {
  props: {
    numItems: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    ShowCart() {
      console.table(this.$store.getters.cartItems);
      this.$router.push('Cart');
    },
  },
};
</script>
