import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Login from '../views/login.vue'


function guardMyroute(to, from, next){
  var isAuthenticated= false; 
  if(localStorage.getItem('user') != null){
    isAuthenticated = true;
  }
  else{
    isAuthenticated= false;
  }
    
  if(isAuthenticated) 
  {
    next();
  } 
  else
  {
    next('/');
  }
}
function guardMyroute2(to, from, next){
  var isAuthenticated= false; 
  if(localStorage.getItem('user') != null){
    isAuthenticated = true;
  }
  else{
    isAuthenticated= false;
  }
  console.log(isAuthenticated);
    
  if(isAuthenticated) 
  {
    next('/');
  } 
  else
  {
    next();
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // beforeEnter : guardMyroute,
    
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter : guardMyroute2,
  },
  {
    path: '/login2',
    name: 'login2',
    component: () => import(/* webpackChunkName: "about" */ '../views/login2.vue'),
    beforeEnter : guardMyroute2,
  },
  {
    path: '/live_stock',
    name: 'LiveStock',
    component: () => import(/* webpackChunkName: "about" */ '../views/LiveStock.vue')
    // beforeEnter : guardMyroute2,
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import(/* webpackChunkName: "about" */ '../views/Cart.vue')
    // beforeEnter : guardMyroute2,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "about" */ '../views/Checkout.vue')
    // beforeEnter : guardMyroute2,
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/register.vue')
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import(/* webpackChunkName: "about" */ '../views/Order.vue'),
    beforeEnter : guardMyroute,
  },
  {
    path: '/delete_account',
    name: 'delete_account',
    component: () => import(/* webpackChunkName: "about" */ '../views/delete_account.vue'),
    // beforeEnter : guardMyroute,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
