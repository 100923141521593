// import Vue from 'vue'
import Vuex from 'vuex'

// Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cart: []
  },
  mutations: {
    addToCart(state, item) {
    if (state.cart.some(obj => obj.id === item.id)) {
        console.table('contains');
    }
    else{state.cart.push(item)}
      
    },
    removeFromCart(state, id) {
        // state.cart.splice(index, 1)
        const index = state.cart.findIndex(obj => obj.id === id);
        if (index !== -1) {
            state.cart.splice(index, 1);
        }
    },
    clearCart(state) {
      state.cart = []
    }
  },
  actions: {
    addToCart({ commit }, item) {
      commit('addToCart', item)
    },
    removeFromCart({ commit }, index) {
      commit('removeFromCart', index)
    },
    clearCart({ commit }) {
      commit('clearCart')
    }
  },
  getters: {
    cartItems(state) {
        return state.cart;
    },
    cartItemCount(state) {
      return state.cart.length
    },
    cartTotal(state) {
      return state.cart.reduce((total, item) => total + item.sellingPrice, 0)
    }
  }
})
