<template>
  <Disclosure as="nav" class="bg-gray-800">
    <div class="mx-3 h-[1vh]">
      <div class="relative flex h-4 items-center justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden"></div>
        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex flex-shrink-0 items-center">
            <img class="block h-12 w-auto lg:hidden" src="@/assets/logo.png" alt="Your Company" />
            <img class="hidden h-12 w-auto lg:block" src="@/assets/logo.png" alt="Your Company" />
          </div>
          <div class="hidden sm:ml-6 sm:block">
            <div class="flex space-x-4">
              <a v-for="item in navigation" :key="item.name" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-yellow-500', 'rounded-md px-3 py-2 text-base font-medium']" :aria-current="item.current ? 'page' : undefined"
                ><router-link :to="item.to" style="text-decoration: none; color: inherit">{{ item.name }}</router-link></a
              >
              <!-- <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-yellow-500', 'rounded-md px-3 py-2 text-base font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a> -->
            </div>
          </div>
        </div>
        <cart-sign :num-items="cartItemCount" />
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <!-- Profile dropdown -->
          <Menu as="div" class="relative ml-3">
            <div v-if="routeName == 'login' || routeName == 'login2'"></div>
            <div v-else-if="logged">
              <MenuButton class="bg-color1 flex text-xl rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-color2 focus:ring-white">
                <span class="sr-only">Open user menu</span>
                <i class="fas fa-user" style="color: white; background-color: "></i>
                <!-- <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" /> -->
              </MenuButton>
            </div>
            <div v-else>
              <button @click="Home()" class="text-white text-base bg-yellow-500 rounded px-2 py-1 hover:bg-yellow-400 hover:text-black hover:scale-105">Log In</button>
            </div>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem>
                  <p class="font-bold">{{ userName }}</p>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a @click="Order()" class="cursor-pointer" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Orders</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a @click="logout()" class="cursor-pointer" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Sign out</a>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pb-3 pt-2">
        <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script >
import api from '../../boot/axios';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import CartSign from './CartSign.vue';

export default {
  name: 'NavbarComponent',
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    CartSign,
  },
  data() {
    return {
      navigation: [
        { name: 'Home', to: '/', current: false },
        { name: 'Qurbani', to: '/live_stock', current: false },
        // { name: 'Categories', to: '/', current: false },
      ],
      logged: true,
      id: localStorage.getItem('user'),
      userName: localStorage.getItem('userName'),
      routeName: this.$route.name,
      numItems: 1,
    };
  },
  computed: {
    cartItemCount() {
      return this.$store.getters.cartItemCount;
    },
  },
  props: ['routepath'],
  created() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.id == null) {
        this.logged = false;
      } else {
        this.logged = true;
      }
      // console.log(this.routeName);
      if (this.routeName == 'LiveStock') {
        this.navigation[1].current = true;
      }
      if (this.routeName == 'Home') {
        this.navigation[0].current = true;
      }
    },
    Home() {
      this.$router.push('login');
    },
    Order() {
      this.$router.push('Order');
    },
    async logout() {
      this.logged = false;
      localStorage.clear();
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .dropdown-menu li {
    position: relative;
  }
  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }
  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }
  .dropdown-menu > li:hover {
    background-color: #f1f1f1;
  }
  .dropdown-menu > li:hover > .submenu {
    display: block;
  }
}
/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {
  .dropdown-menu .dropdown-menu {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: 0.5rem;
  }
}
/* ============ small devices .end// ============ */
</style>