<template>
  <div id="content" style="height: 100vh">
    <NavbarNotLoggedIn />
    <NotificationGroup group="error">
      <div class="fixed inset-0 flex items-end justify-end p-6 px-4 py-6 pointer-events-none">
        <div class="w-full max-w-sm">
          <Notification v-slot="{ notifications }" enter="transform ease-out duration-300 transition" enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4" enter-to="translate-y-0 opacity-100 sm:translate-x-0" leave="transition ease-in duration-500" leave-from="opacity-100" leave-to="opacity-0" move="transition duration-500" move-delay="delay-300">
            <div class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md" v-for="notification in notifications" :key="notification.id">
              <div class="flex items-center justify-center w-12 bg-red-500">
                <svg class="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                </svg>
              </div>

              <div class="px-4 py-2 -mx-3">
                <div class="mx-3">
                  <span class="font-semibold text-red-500">{{ notification.title }}</span>
                  <p class="text-sm text-gray-600">{{ notification.text }}</p>
                </div>
              </div>
            </div>
          </Notification>
        </div>
      </div>
    </NotificationGroup>
    <div class="">
      <div class="">
        <div class="grid place-items-center h-[80vh]">
          <div v-if="varr == 0" class="w-[500px] rounded overflow-hidden shadow-lg">
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2 border-1" style="border-bottom: 1px solid black">Sign in</div>
              <div class="md:flex md:items-center my-6">
                <div class="md:w-[150px] ml-[15px]">
                  <label class="block text-gray-500 font-bold md:text-right pr-4" for="inline-full-name"> Phone: </label>
                </div>
                <div class="md:w-[350px]">
                  <input v-model="phone" class="md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-0 focus:bg-gray-100 focus:border-orange-700 w-[250px]" type="phone" placeholder="Phone Number" />
                </div>
              </div>
              <button class="bg-buttonAccent1 hover:bg-buttonAccentHover w-[270px] text-white font-bold py-2 mx-1 rounded" type="submit" @click="Submit()">Send OTP</button>
              <br />
              <p @click="Go()" class="mt-2 cursor-pointer underline text-base text-buttonAccentHover hover:text-red-500">Employee, Log in Here</p>
            </div>
          </div>
          <div v-if="varr == 1" class="w-[500px] rounded overflow-hidden shadow-lg">
            <div class="px-6 py-4">
              <div class="text-left">
                <p
                  @click="
                    varr = 0;
                    phone = '';
                  "
                >
                  <i class="fa-solid fa-arrow-left-long hover:border-b-2 hover:border-black"></i>
                </p>
              </div>
              <div class="font-bold text-xl mb-2 border-1" style="border-bottom: 1px solid black">Sign in</div>
              <div class="md:flex md:items-center my-6">
                <div class="md:w-[150px] ml-[15px]">
                  <label class="block text-gray-500 font-bold md:text-right pr-4" for="inline-full-name"> OTP: </label>
                </div>
                <div class="md:w-[350px]">
                  <input v-model="otp" class="md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-0 focus:bg-gray-100 focus:border-orange-700 w-[250px]" type="number" placeholder="OTP" />
                </div>
              </div>
              <button class="bg-buttonAccent1 hover:bg-buttonAccentHover w-[270px] text-white font-bold py-2 mx-1 rounded" type="submit" @click="SubmitOTP()">Check OTP</button>
              <br />
            </div>
          </div>
          <div v-if="varr == 2" class="w-[500px] rounded overflow-hidden shadow-lg">
            <div class="px-6 py-4">
              <div class="text-left">
                <p
                  @click="
                    varr = 1;
                    otp = '';
                  "
                >
                  <i class="fa-solid fa-arrow-left-long hover:border-b-2 hover:border-black"></i>
                </p>
              </div>
              <div class="font-bold text-xl mb-2 border-1" style="border-bottom: 1px solid black">Sign Up</div>
              <div class="md:flex md:items-center my-6">
                <div class="md:w-[150px] ml-[15px]">
                  <label class="block text-gray-500 font-bold md:text-right pr-4" for="inline-full-name"> Name: </label>
                </div>
                <div class="md:w-[350px]">
                  <input v-model="name" class="md:flex appearance-none border-2 border-gray-400 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-0 focus:bg-gray-100 focus:border-orange-700 w-[250px]" type="text" placeholder="Name" />
                </div>
              </div>
              <button class="bg-buttonAccent1 hover:bg-buttonAccentHover w-[270px] text-white font-bold py-2 mx-1 rounded" type="submit" @click="SingUp()">Sign Up</button>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../boot/axios';
import NavbarNotLoggedIn from '@/components/NavbarNotLoggedIn.vue';
import { notify } from 'notiwind';

export default {
  name: 'login',
  components: { NavbarNotLoggedIn },
  data() {
    return {
      phone: '',
      otp: '',
      name: '',
      checkData: [],
      varr: 0,
    };
  },
  methods: {
    async Submit() {
      await api
        .post(`auth/cus_otp_sent`, { phone: this.phone })
        .then((res) => {
          this.checkData = res.data;
          this.varr = 1;
        })
        .catch((error) => {
          console.log(error);
        });
      console.log(this.checkData);
    },
    async SubmitOTP() {
      var jwtToken;
      await api
        .post(`auth/otp_check`, { phone: this.phone, otp: this.otp, check: false })
        .then((res) => {
          console.log(res.data);
          jwtToken = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
      if (jwtToken.match == false) {
        this.toastDanger('Wrong', 'OTP is does not Match');
        this.otp = '';
      } else if (jwtToken.user == 'not registered') {
        this.toastDanger('New User', 'Please give your name');
        this.varr = 2;
      } else {
        await api
          .get(`api/customer/${jwtToken.user}/`)
          .then((res) => {
            localStorage.setItem('userName', res.data.userName + ' (Customer)');
            localStorage.setItem('user', jwtToken.user);
            localStorage.setItem('token', jwtToken.utoken);
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    Go() {
      this.$router.push({ name: 'login2' });
    },

    async SingUp() {
      await api
        .post(`api/customer/`, {
          userName: this.name,
          phone: this.phone,
          internalEmployee: false,
        })
        .then((res) => {
          console.log(res.data);
          api
            .post(`auth/otp_check`, { phone: this.phone, otp: this.otp, check: false })
            .then((r) => {
              console.log(r.data);
              let jwtToken = r.data;
              localStorage.setItem('userName', res.data.userName + ' (Customer)');
              localStorage.setItem('user', jwtToken.user);
              localStorage.setItem('token', jwtToken.utoken);
              this.$router.go(-1);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    Back() {
      console.log(true);
    },
    toastDanger(w, q) {
      notify(
        {
          group: 'error',
          title: w,
          text: q,
        },
        1500
      );
    },
  },
};
</script>

<style scoped>
.card {
  margin: 60px auto; /* Added */
  float: none; /* Added */

  padding: 40px;
}
</style>